<template>
    <div>
        <div class="swipe-frame">
            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                <van-swipe-item>
                    <img class="swipe-img" src="../../statics/images/ad1.jpg">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="swipe-img" src="../../statics/images/ad2.jpg">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="swipe-img" src="../../statics/images/ad3.jpg">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="swipe-img" src="../../statics/images/ad4.jpg">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="swipe-img" src="../../statics/images/ad5.jpg">
                </van-swipe-item>
            </van-swipe>
        </div>
        <van-notice-bar v-if="loginType == LOGINTYPE_CHILD" left-icon="volume-o" :text="'少儿培训｜'+ruleTitle+'班报名进行中，欢迎学员报名。'"/>
        <van-notice-bar v-else left-icon="volume-o" :text="'老人大学｜'+ruleTitle+'班报名进行中，欢迎学员报名。'"/>
        <div class="edu-group" style="margin: 20px auto;padding: 0px;overflow: hidden;">
            <van-grid :column-num="3" >

                <van-grid-item @click="$router.push({ name: 'xzkcgroup' })" v-if="(customer.lgs_CardType1 == 2 || customer.isStudentCard == 1) && identityType == 'childGroup'">
                    <img class="grid-item-img" src="../../statics/images/wybm.png">
                    <div class="grid-item-tips">社团报名</div>
                </van-grid-item>
                <van-grid-item @click="$router.push({ name: 'bdxz' })" v-if="(customer.lgs_CardType1 == 2 || customer.isStudentCard == 1) && identityType == 'single'">
                  <img class="grid-item-img" src="../../statics/images/wybm.png">
                  <div class="grid-item-tips">我要报名</div>
                </van-grid-item>
                <van-grid-item @click="$router.push({ name: 'qrkc' })" v-if="customer.lgs_CardType1 == 2 || customer.isStudentCard == 1">
                    <img class="grid-item-img" src="../../statics/images/wyjf.png">
                    <div class="grid-item-tips">我要缴费</div>
                </van-grid-item>
                <van-grid-item @click="$router.push({ name: 'ybdbj' })" v-if="customer.lgs_CardType1 == 2 || customer.isStudentCard == 1">
                    <img class="grid-item-img" src="../../statics/images/ybmbj.png">
                    <div class="grid-item-tips">已报读班级</div>
                </van-grid-item>
                <van-grid-item @click="$router.push({ name: 'zlxg' })" v-if="customer.lgs_CardType1 == 2">
                    <img class="grid-item-img" src="../../statics/images/zlxg.png">
                    <div class="grid-item-tips">资料修改</div>
                </van-grid-item>
                <van-grid-item @click="$router.push({ name: 'jszlxg' })" v-if="customer.lgs_CardType1 == 8">
                  <img class="grid-item-img" src="../../statics/images/zlxg.png">
                  <div class="grid-item-tips">资料修改</div>
                </van-grid-item>
                <van-grid-item @click="$router.push({ name: 'sczp' })" v-if="customer.lgs_CardType1 == 2 || customer.isStudentCard == 1">
                    <img class="grid-item-img" src="../../statics/images/sczp.png">
                    <div class="grid-item-tips">上传照片</div>
                </van-grid-item>
                <van-grid-item @click="$router.push({ name: 'tkcx' })" v-if="customer.lgs_CardType1 == 2 || customer.isStudentCard == 1">
                    <img class="grid-item-img" src="../../statics/images/tkcx.png">
                    <div class="grid-item-tips">退款查询</div>
                </van-grid-item>
                <!-- <van-grid-item @click="$router.push({ name: 'hdgl' })" v-if="customer.lgs_CardType1 == 2">
                    <img class="grid-item-img" src="../../statics/images/activity.png">
                    <div class="grid-item-tips">活动管理</div>
                </van-grid-item> -->
                <van-grid-item @click="$router.push({ name: 'rkjh' })" v-if="customer.lgs_CardType1 == 8">
                  <img class="grid-item-img" src="../../statics/images/rkjh.png">
                  <div class="grid-item-tips">教师任课计划</div>
                </van-grid-item>
                <van-grid-item @click="$router.push({ name: 'jxjh' })" v-if="customer.lgs_CardType1 == 8">
                    <img class="grid-item-img" src="../../statics/images/jxjh.png">
                    <div class="grid-item-tips">教学计划填写</div>
                </van-grid-item>
<!--              <van-grid-item @click="$router.push({ name: 'bmqk' })" v-if="customer.lgs_CardType1 == 8 && customer.canCheckRegClass == 1">
                <img class="grid-item-img" src="../../statics/images/bmqk.png">
                <div class="grid-item-tips">班级报名情况</div>
              </van-grid-item>-->
                <van-grid-item @click="$router.push({ name: 'qjsq' })" v-if="customer.lgs_CardType1 == 8">
                    <img class="grid-item-img" src="../../statics/images/qjsq.png">
                    <div class="grid-item-tips">请假申请</div>
                </van-grid-item>
              <van-grid-item @click="$router.push({ name: 'tktz' })" v-if="showTktz">
                <img class="grid-item-img" src="../../statics/images/tktz.png">
                <div class="grid-item-tips">停课通知</div>
              </van-grid-item>
                <van-grid-item @click="$router.push({ name: 'xgmm' })">
                    <img class="grid-item-img" src="../../statics/images/xgmm.png">
                    <div class="grid-item-tips">修改密码</div>
                </van-grid-item>
                <van-grid-item @click="bindWechat()">
                    <img class="grid-item-img" src="../../statics/images/bdwx.png">
                    <div class="grid-item-tips">{{ customer.weixinOpenId == null || customer.weixinOpenId == '' ? '绑定微信' : '解绑微信' }}</div>
                </van-grid-item>



            </van-grid>
        </div>

    </div>
</template>
<script>
    import { Swipe, SwipeItem, Grid, GridItem, NoticeBar } from 'vant';
    import CustomerApi from "../api/CustomerApi";
    import Tools from "../api/Tools";
    import QxUsersApi from "@/api/QxUsersApi";
    import EduConstants from "@/api/EduConstants";
    import BaomingApi from "@/api/BaomingApi";
    export default {
        name: 'mainIndex',
        components: {
            VanSwipe: Swipe,
            VanSwipeItem: SwipeItem,
            VanNoticeBar: NoticeBar,
            VanGrid: Grid,
            VanGridItem: GridItem
        },
        data() {
            return {
                customer: Tools.getCurCust(),
                identityType: Tools.getIdentityType(),
                showTktz:  false,
                loginType: Tools.getLoginType(),
                LOGINTYPE_CHILD: EduConstants.LOGINTYPE_CHILD,
                ruleTitle: ''
            }
        },
        methods: {
            bindWechat() {
                var openId = null
                if (this.customer.weixinOpenId == null) {
                    openId = Tools.getSession('openId')
                } else {
                    openId = null
                }
                // this.loading=true

                CustomerApi.bindOfficalOpenId({ custId: this.customer.custId, weixinOpenId: openId }).then(response => {
                    // this.loading=false
                    if (response.code==100){
                      this.$set(this.customer, 'weixinOpenId', openId)
                        Tools.setCurCust(this.customer)

                        if (openId == null) {
                            this.$toast('解绑成功。')
                        } else {
                            this.$toast('绑定成功。')
                        }

                    } else {
                        this.$toast('绑定失败，错误提示：' + response.msg)
                    }
                }).catch(()=>{
                    // this.loading=false
                })
            },
            getRuleTitle() {
              BaomingApi.getRecruitRuleTitle(this.customer).then(response => {
                this.loading = false
                this.ruleTitle = response.res
                // console.log(this.rules)
              }).catch(e => {
                this.loading = false
                this.$router.replace({ name: 'error', params: { msg: e.data.msg } })
              })
            }
        },
      mounted() {
        this.getRuleTitle()
        if (this.customer.lgs_CardType1 == 0 || this.customer.lgs_CardType1 == 8) {
          QxUsersApi.getUserRightsByUserCode(this.customer.custCode).then(response => {
            for (var i = 0; i < response.res.length; i++) {
              if (response.res[i].funcId == 'tktz' && response.res[i].fview == 1) {
                this.showTktz = true
              }
            }
          })
        }

      }
    }
</script>
<style scoped>
    .my-swipe .van-swipe-item {
        color: #fff;
        font-size: 20px;
        text-align: center;
        height: 160px;
    }
    .swipe-img {
        width: 100%;
        height: 100%;
    }
    .swipe-frame {
        width: 100%;
        position: relative;
    }
    .grid-item-img {
        width: 30px;
        height: auto;
    }
    .grid-item-tips {
        margin-top: 10px;
        font-size: 14px;
    }
</style>
